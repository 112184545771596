export var sourceData = [
    {
        code: "google",
        name: "Google"
    },
    {
        code: "centerforsalesstrategy",
        name: "The Center for Sales Strategy"
    },
    {
        code: "google-cloud",
        name: "Google Cloud"
    },
    {
        code: "learningevolution",
        name: "Learning Evolution"
    },
    {
        code: "komo",
        name: "Komo"
    },
    {
        code: "axios",
        name: "Axios"
    },
    {
        code: "inc",
        name: "Inc."
    },
    {
        code: "startup-savant",
        name: "Startup Savant"
    },
    {
        code: "msforstartups",
        name: "Microsoft for Startups"
    },
    {
        code: "wsj",
        name: "The Wall Street Journal"
    },
    {
        code: "toastmasters",
        name: "Toastmasters"
    },
    {
        code: "geekwire",
        name: "Geekwire"
    },
    {
        code: "built-in",
        name: "Built In"
    },
    {
        code: "chris-haroun",
        name: "Chris Haroun"
    },
    {
        code: "pr-newswire",
        name: "PR Newswire"
    },
    {
        code: "forbes",
        name: "Forbes"
    },
    {
        code: "zdnet",
        name: "ZdNet"
    },
    {
        code: "puget-sound-business-journals",
        name: "Puget Sound Business Journals"
    },
    {
        code: "pr-week",
        name: "PR Week"
    },
    {
        code: "product-hunt",
        name: "Product Hunt"
    },
    {
        code: "madrona",
        name: "Madrona"
    },
    {
        code: "speech-technology",
        name: "Speech Technology"
    },
    {
        code: "hire-power-radio-show",
        name: "Hire Power Radio Show"
    }
];
export var articleData = [
    {
        title: "Trump's inauguration word choices give insight into Trump 2.0",
        url: "https://www.axios.com/2025/01/20/trump-inauguration-speech-words-said-most-2025",
        source: "axios",
        image: "/assets/press-images/press_axios2.png",
        isFeatured: true
    },
    {
        title: "Yoodli boosts confidence of job applicants, presenters, and minorities with automated video coaching platform powered by Google Cloud",
        url: "https://cloud.google.com/blog/topics/startups/yoodli-launches-presentation-coaching-platform-with-google-cloud",
        source: "google-cloud",
        image: "/assets/press-images/press_GoogleCloud.png",
        isFeatured: true
    },
    {
        title: "The Future of Sales Coaching with Varun Puri & Ajay Jain",
        url: "https://blog.thecenterforsalesstrategy.com/revolutionizing-sales-coaching-with-ai-insights-from-yoodli",
        source: "centerforsalesstrategy",
        image: "/assets/press-images/press_centerforsalesstrategy.png",
        isFeatured: false
    },
    {
        title: "Here’s how AI graded President Trump’s inauguration speech",
        url: "https://www.geekwire.com/2025/heres-how-ai-graded-president-trumps-inauguration-speech/",
        source: "geekwire",
        image: "/assets/press-images/press_geekwire6.png",
        isFeatured: false
    },
    {
        title: "Learning Evolution and Yoodli Partner to Revolutionize CPG and Beverage Sales Training with AI-Powered Roleplay",
        url: "https://www.kron4.com/business/press-releases/ein-presswire/776637066/learning-evolution-and-yoodli-partner-to-revolutionize-cpg-and-beverage-sales-training-with-ai-powered-roleplay/",
        source: "learningevolution",
        image: "/assets/press-images/press_learningevolution.png",
        isFeatured: false
    },
    {
        title: "Generative AI as a speech coach",
        url: "https://www.axios.com/2024/02/29/generative-ai-public-speaking-speech-yoodli",
        source: "axios",
        image: "/assets/press-images/press_axios.png",
        isFeatured: false
    },
    {
        title: "Major Moves: Can Artificial Intelligence improve your public speaking?",
        url: "https://www.youtube.com/watch?v=wyH5izRmIRY",
        source: "komo",
        image: "/assets/press-images/press_komo.png",
        isFeatured: true
    },
    {
        title: "Fueling your startup: why fundraising and investment readiness matter",
        url: "https://startups.microsoft.com/blog/fueling-your-startup-why-fundraising-and-investment-readiness-matter/",
        source: "msforstartups",
        image: "/assets/press-images/press_microsoft.png",
        isFeatured: false
    },
    {
        title: "AI-powered 'VC pitch coach' from Yoodli helps startup founders prepare to face investors",
        url: "https://www.geekwire.com/2023/ai-powered-vc-pitch-coach-from-yoodli-helps-startup-founders-prepare-to-face-investors/",
        source: "geekwire",
        image: "/assets/press-images/press_geekwire5.png",
        featuredLogo: "/assets/press-images/geekwire-featured-logo.png",
        isFeatured: false
    },
    {
        title: "31 Top Seattle Startups to Watch in 2023",
        url: "https://startupsavant.com/startups-to-watch/seattle",
        source: "startup-savant",
        image: "/assets/press-images/press_startup-savant1.png",
        isFeatured: false
    },
    {
        title: "Speech analysis startup releases AI tool that simulates difficult job interview conversations",
        url: "https://www.geekwire.com/2023/speech-analysis-startup-releases-ai-tool-that-simulates-difficult-job-interview-conversations/",
        source: "geekwire",
        image: "/assets/press-images/press_geekwire4.png",
        isFeatured: false
    },
    {
        title: "Toastmasters adopts AI-powered speech analytics technology from Seattle startup Yoodli",
        url: "https://www.geekwire.com/2022/toastmasters-adopts-ai-powered-speech-analytics-technology-from-seattle-startup-yoodli/",
        source: "geekwire",
        image: "/assets/press-images/press_geekwire2.png",
        isFeatured: false
    },
    {
        title: "Seattle Startups to Watch in 2023",
        url: "https://www.inc.com/inc-masters/seattle-startups-to-watch.html",
        source: "inc",
        image: "/assets/press-images/press_inc.png",
        isFeatured: false
    },
    {
        title: "ChatGPT Spotlights Microsoft’s Early Efforts to Monetize AI",
        url: "https://www.wsj.com/articles/chatgpt-spotlights-microsofts-early-efforts-to-monetize-ai-11674964348",
        source: "wsj",
        image: "/assets/press-images/press_wsj.png",
        isFeatured: false
    },
    {
        title: "Introducing the Yoodli Partnership",
        url: "https://www.toastmasters.org/magazine/magazine-issues/2023/jan/introducing-yoodli",
        source: "toastmasters",
        image: "/assets/press-images/press_toastmasters.png",
        isFeatured: false
    },
    {
        title: "These are Seattle Inno's 23 startups to watch in 2023",
        url: "https://www.bizjournals.com/seattle/inno/stories/awards/2023/01/10/startups-to-watch-seattle-inno-2023.html",
        source: "puget-sound-business-journals",
        image: "/assets/press-images/press_pugetsoundbizjournals.png",
        isFeatured: false
    },
    {
        title: "ToastCaster 168: Yoodli - Your AI-Powered Public Speaking Coach - Varun Puri",
        url: "https://toastcaster.podbean.com/e/toastcaster-168-yoodli-your-ai-powered-public-speaking-coach-varun-puri/",
        source: "toastmasters",
        image: "/assets/press-images/press_TMpodcast.png",
        isFeatured: false
    },
    {
        title: "Microsoft Expands Access to OpenAI’s Tools to More Customers",
        url: "https://www.wsj.com/articles/microsoft-expands-access-to-openais-tools-to-more-customers-11673930847",
        source: "wsj",
        image: "/assets/press-images/press_wsj.png",
        isFeatured: false
    },
    {
        title: "Yoodli voted Product of the day on Product Hunt",
        url: "https://www.producthunt.com/posts/yoodli",
        source: "product-hunt",
        image: "/assets/press-images/press_producthunt.png",
        featuredLogo: "/assets/press-images/product-of-day-badge.png",
        isFeatured: false
    },
    {
        title: "The Future 5 of Seattle Tech, Q4 2021",
        url: "https://www.builtinseattle.com/2021/10/19/seattle-future-5-startups-q4-2021",
        source: "built-in",
        image: "/assets/press-images/press_builtinsea.png",
        featuredLogo: null,
        isFeatured: false
    },
    {
        title: "Yoodli Cofounders make Forbes 30 under 30 List for Consumer Technology",
        url: "https://www.forbes.com/profile/yoodli/?sh=2c0ffccc7ad0",
        source: "forbes",
        image: "/assets/press-images/press_forbes.png",
        featuredLogo: null,
        isFeatured: false
    },
    {
        title: "Elon Musk Says 'Um' 20 Times in a 5-Minute Speech. Here's How He Could Fix It--and So Can You",
        url: "https://www.inc.com/minda-zetlin/improve-public-speaking-elon-musk-speech-yoodli.html",
        source: "inc",
        image: "/assets/press-images/press_inc.png",
        featuredLogo: null,
        isFeatured: false
    },
    {
        title: "Toastmasters International Partners with AI-powered Public Speaking Platform, Yoodli",
        url: "https://mediacenter.toastmasters.org/2022-09-08-Toastmasters-International-Partners-with-AI-powered-Public-Speaking-Platform,-Yoodli",
        source: "toastmasters",
        image: "/assets/press-images/press_toastmasters.png",
        featuredLogo: null,
        isFeatured: false
    },
    {
        title: "Afraid of public speaking? This AI can help",
        url: "https://www.zdnet.com/article/afraid-of-public-speaking-this-ai-tool-can-help/",
        source: "zdnet",
        image: "/assets/press-images/press_zdnet.png",
        featuredLogo: null,
        isFeatured: false
    },
    {
        title: "Seattle AI startup that helps professionals improve public speaking by analyzing speech...",
        url: "https://www.geekwire.com/2022/seattle-ai-startup-that-helps-professionals-improve-public-speaking-by-analyzing-speech-raises-6m/",
        source: "geekwire",
        image: "/assets/press-images/press_geekwire1.png",
        featuredLogo: null,
        isFeatured: false
    },
    {
        title: "Terrified of Public Speaking? Finally, There’s An App For That.",
        url: "https://www.madrona.com/yoodli/",
        source: "madrona",
        image: "/assets/press-images/press_madrona.png",
        featuredLogo: null,
        isFeatured: false
    },
    {
        title: "Yoodli opens up its free website to improve public speaking — and adds in some games",
        url: "https://www.geekwire.com/2022/yoodli-opens-up-its-free-website-to-improve-public-speaking-and-adds-in-some-games/",
        source: "geekwire",
        image: "/assets/press-images/press_geekwire2.png",
        featuredLogo: null,
        isFeatured: false
    },
    {
        title: "Public speaking site Yoodli teams with Toastmasters International",
        url: "https://www.prweek.com/article/1802743/public-speaking-site-yoodli-teams-toastmasters-international",
        source: "pr-week",
        image: "/assets/press-images/press_prweek.png",
        featuredLogo: null,
        isFeatured: false
    },
    {
        title: "Master Public Speaking With The Power Of AI | Innovative Business Idea (Lessons from Business Icons)",
        url: "https://www.youtube.com/watch?v=GrWkcsVvkuA&ab_channel=ChrisHaroun",
        source: "chris-haroun",
        image: "/assets/press-images/press_haroun1.png",
        featuredLogo: null,
        isFeatured: false
    },
    {
        title: "This Seattle AI startup wants to help you nail your next big speech",
        url: "https://www.bizjournals.com/seattle/news/2022/04/13/yoodli-launches-out-of-stealth.html",
        source: "puget-sound-business-journals",
        image: "/assets/press-images/press_pugetsoundbizjournals.png",
        featuredLogo: null,
        isFeatured: false
    },
    {
        title: "Weekly LIVE Q&A #209",
        url: "https://www.youtube.com/watch?v=Miqs4bqt0hM&ab_channel=ChrisHaroun",
        source: "chris-haroun",
        image: "/assets/press-images/press_haroun2.png",
        featuredLogo: null,
        isFeatured: false
    },
    {
        title: "The Blind Spots of Hiring Managers & Candidates with Varun Puri",
        url: "https://www.youtube.com/watch?v=UCK8mQK0ecg",
        source: "hire-power-radio-show",
        image: "/assets/press-images/press_hirepower.png",
        featuredLogo: null,
        isFeatured: false
    },
    {
        title: "Toastmasters Partners with Yoodli",
        url: "https://www.speechtechmag.com/Articles/News/Speech-Technology-News/Toastmasters-Partners-with-Yoodli-154871.aspx",
        source: "speech-technology",
        image: "/assets/press-images/press_speechtech.png",
        featuredLogo: null,
        isFeatured: false
    },
    {
        title: "Startups, superheroes, and the economy at a crossroads: Voices from Seattle’s tech community",
        url: "https://www.geekwire.com/2022/startups-superheroes-and-the-economy-at-a-crossroads-voices-from-seattles-tech-community/",
        source: "geekwire",
        image: "/assets/press-images/press_geekwire3.png",
        featuredLogo: null,
        isFeatured: false
    },
    {
        title: "PRophet, a Stagwell (STGW) Marketing Cloud Product, Partners with Paul Allen AI Institute's Yoodli to Bring AI Speech Coaching and Media Training to the PR Industry",
        url: "https://www.prnewswire.com/news-releases/prophet-a-stagwell-stgw-marketing-cloud-product-partners-with-paul-allen-ai-institutes-yoodli-to-bring-ai-speech-coaching-and-media-training-to-the-pr-industry-301690375.html",
        source: "pr-newswire",
        image: "/assets/press-images/press_prnewswire.png",
        featuredLogo: null,
        isFeatured: false
    }
];
